export const isLineMock = () => {
  const { VITE_LINE_MOCK = "" } = import.meta.env
  return VITE_LINE_MOCK === "true"
}

export const kumaCompanyId = () => {
  const { VITE_KUMA_COMPANY_ID } = import.meta.env
  return VITE_KUMA_COMPANY_ID
}

export const expoCompanyId = () => {
  const { VITE_EXPO_COMPANY_ID } = import.meta.env
  return VITE_EXPO_COMPANY_ID
}

export const traneCompanyId = () => {
  const { VITE_TRANE_COMPANY_ID } = import.meta.env
  return VITE_TRANE_COMPANY_ID
}

export const toaCompanyId = () => {
  const { VITE_TOA_COMPANY_ID } = import.meta.env
  return VITE_TOA_COMPANY_ID
}

export const lionTravelCompanyId = () => {
  const { VITE_LION_TRAVEL_COMPANY_ID } = import.meta.env
  return VITE_LION_TRAVEL_COMPANY_ID
}

export const lion40CompanyId = () => {
  const { VITE_LION_40_COMPANY_ID } = import.meta.env
  return VITE_LION_40_COMPANY_ID
}

export const ostechCompanyId = () => {
  const { VITE_OSTECH_COMPANY_ID } = import.meta.env
  return VITE_OSTECH_COMPANY_ID
}

export const titanCompanyId = () => {
  const { VITE_TITAN_COMPANY_ID } = import.meta.env
  return VITE_TITAN_COMPANY_ID
}

export const showConsoleLog = () => {
  const { VITE_SHOW_CONSOLE_LOG } = import.meta.env
  return VITE_SHOW_CONSOLE_LOG
}
